<template>
    <div class="footer">
        <div class="row col-md-12" id="texto-contacto">
            <p style="margin:auto;">Para consultas, contacte a <a style="text-decoration:none;" href="mailto:estudiosestadisticas@minciencia.gob.cl">estudiosestadisticas@minciencia.gob.cl</a></p>
        </div>
        <div class="foot"></div>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import router from '../router/index.js'
// import ModalPassword from '@/components/ModalPassword.vue'

const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
export default {
    name: 'Header',
    components: {
        // ModalPassword
    },
    data () {
        return {
            usuario: {},
            activeClass: 'nav-link--active',
            pathName: this.$route.name,
            logoLink: '/'
        }
    },
    computed: {
        currentPage () {
            return this.$route.path
        }
    },
    methods: {
        logout () {
            router.push('/login?logout')
            location.reload()
        }
    },
    mounted () {
        if (this.pathName === 'Login') this.logoLink = ''
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
        })
    }
}
</script>
