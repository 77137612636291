import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PanelAdministracion from '../views/PanelAdministracion.vue'
import Login from '../views/Login.vue'
import ListarFichasDiseno from '../views/ListarFichasDiseno.vue'
import ListarFichasEvaluacion from '../views/ListarFichasEvaluacion.vue'
import CrearFicha from '../views/CrearFicha.vue'
import ListarFichas from '../views/ListarFichas.vue'
import PreviewFicha from '../views/PreviewFicha.vue'
import CrearAgencia from '../views/CrearAgencia.vue'
import ListarAgencias from '../views/ListarAgencias.vue'
import EditarUsuario from '../views/EditarUsuario.vue'
import ListarUsuarios from '../views/ListarUsuarios.vue'
import FichaInstrumento from '../views/FichaInstrumento.vue'
import FichaIndicadores from '../views/FichaIndicadores.vue'
import FichaTeoriaCambio from '../views/FichaTeoriaCambio.vue'
import CrearTipoIndicador from '../views/CrearTipoIndicador.vue'
import CrearUnidadMedida from '../views/CrearUnidadMedida.vue'
import ListarMonitoreos from '../views/ListarMonitoreos.vue'
import CrearMonitoreo from '../views/CrearMonitoreo.vue'
import ListarMonitoreoInstrumentos from '../views/ListarMonitoreoInstrumentos.vue'
import MonitoreoInstrumento from '../views/MonitoreoInstrumento.vue'
import MonitoreoProcesos from '../views/MonitoreoProcesos.vue'
import MonitoreoDesempeno from '../views/MonitoreoDesempeno.vue'
import Reportes from '../views/Reportes.vue'
import MaterialInteres from '../views/MaterialInteres.vue'

import axios from 'axios'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Home', component: Home, meta: { noAuth: true } },
    { path: '/login', name: 'Login', component: Login, meta: { noAuth: true } },
    { path: '/panel_administracion', name: 'PanelAdministracion', component: PanelAdministracion, meta: { noAuth: false } },
    { path: '/fichas_diseno', name: 'ListarFichasDiseno', component: ListarFichasDiseno, meta: { noAuth: false } },
    { path: '/fichas_evaluacion', name: 'ListarFichasEvaluacion', component: ListarFichasEvaluacion, meta: { noAuth: false } },
    { path: '/crear_ficha', name: 'CrearFicha', component: CrearFicha, meta: { noAuth: false } },
    { path: '/crear_ficha/:id', name: 'CrearSecciones', component: CrearFicha, meta: { noAuth: false } },
    { path: '/listar_fichas', name: 'ListarFichas', component: ListarFichas, meta: { noAuth: false } },
    { path: '/preview_ficha/:id', name: 'PreviewFicha', component: PreviewFicha, meta: { noAuth: false } },
    { path: '/crear_agencia', name: 'CrearAgencia', component: CrearAgencia, meta: { noAuth: false } },
    { path: '/crear_agencia/:id', name: 'CrearSubdirecciones', component: CrearAgencia, meta: { noAuth: false } },
    { path: '/listar_agencias', name: 'ListarAgencias', component: ListarAgencias, meta: { noAuth: false } },
    { path: '/editar_usuario/:id', name: 'EditarUsuario', component: EditarUsuario, meta: { noAuth: false } },
    { path: '/listar_usuarios', name: 'ListarUsuarios', component: ListarUsuarios, meta: { noAuth: false } },
    { path: '/ficha/:fichaId/:instrumentoId/:anio', name: 'FichaInstrumento', component: FichaInstrumento, meta: { noAuth: false } },
    { path: '/fichaindicadores/:fichaId/:instrumentoId/:anio', name: 'FichaIndicadores', component: FichaIndicadores, meta: { noAuth: false } },
    { path: '/fichateoriacambio/:fichaId/:instrumentoId/:anio', name: 'FichaTeoriaCambio', component: FichaTeoriaCambio, meta: { noAuth: false } },
    { path: '/crear_tipo_indicador', name: 'CrearTipoIndicador', component: CrearTipoIndicador, meta: { noAuth: false } },
    { path: '/crear_unidad_medida', name: 'CrearUnidadMedida', component: CrearUnidadMedida, meta: { noAuth: false } },
    { path: '/listar_monitoreos', name: 'ListarMonitoreos', component: ListarMonitoreos, meta: { noAuth: false } },
    { path: '/crear_monitoreo', name: 'CrearMonitoreo', component: CrearMonitoreo, meta: { noAuth: false } },
    { path: '/crear_monitoreo/:id', name: 'CrearPlanillas', component: CrearMonitoreo, meta: { noAuth: false } },
    { path: '/monitoreo_instrumentos', name: 'ListarMonitoreoInstrumentos', component: ListarMonitoreoInstrumentos, meta: { noAuth: false } },
    { path: '/monitoreoinstrumento/:monitoreoId/:instrumentoId/:anio', name: 'MonitoreoInstrumento', component: MonitoreoInstrumento, meta: { noAuth: false } },
    { path: '/monitoreoagencia/:monitoreoId/:agenciaId/:anio', name: 'MonitoreoProcesos', component: MonitoreoProcesos, meta: { noAuth: false } },
    { path: '/monitoreoinstrumento/:monitoreoId/:instrumentoId/:indicadorId/:monitoreoInstrumentoId/:anio', name: 'MonitoreoDesempeno', component: MonitoreoDesempeno, meta: { noAuth: false } },
    { path: '/reportes', name: 'Reportes', component: Reportes, meta: { noAuth: false } },
    { path: '/material_interes', name: 'MaterialInteres', component: MaterialInteres, meta: { noAuth: false } }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        } else {
            return { x: 0, y: 0 }
        }
    }
    // linkActiveClass: 'nav-link--active',
    // linkExactActiveClass: 'nav-link--active'
})

router.beforeEach(function (to, from, next) {
    if (to.fullPath === '/login?logout') {
        localStorage.removeItem('jwt.access')
        localStorage.removeItem('jwt.refresh')
        next({
            path: '/login'
        })
        return
    }
    if (!to.meta.noAuth) {
        if (localStorage.getItem('jwt.access') === null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
            return
        }
    }
    next()
})
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwt.access')
        // console.log(token)
        // console.log(config.url)

        if (config.url.search('http') !== 0) {
            config.url = process.env.VUE_APP_BASEURL + config.url
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        // console.log(config.url)
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    // console.log(error)
    // console.log(error.response)
    const originalRequest = error.config
    if (error.response.status === 401 && error.response.data && error.response.data.code === 'token_not_valid') {
        if (error.config.url.search('/api/token/refresh/') >= 0) {
            localStorage.removeItem('jwt.access')
            router.push('/login')
            location.reload()
            return Promise.reject(error)
        }
        axios.interceptors.response.eject()
        const token = localStorage.getItem('jwt.access')
        const refreshToken = localStorage.getItem('jwt.refresh')
        return axios.post(process.env.VUE_APP_BASEURL + '/api/token/refresh/', { access: token, refresh: refreshToken })
            .then(function (res) {
                if (res.status === 200) {
                    localStorage.setItem('jwt.access', res.data.access)
                    return axios(originalRequest)
                }
            })
    }
    return Promise.reject(error)
})
export default router
