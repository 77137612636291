<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <b-modal id="modal-cargar-archivos" size="lg" title="Cargar Archivos">
            <div class="row">
                <div class="form-group col-md-10">
                    <label>Archivos*</label>
                    <b-form-file multiple :accept="acceptedFiles" v-model="files" drop-placeholder="Deja aquí los archivos" placeholder="No hay archivos seleccionados" ref="files">
                       <template slot="file-name" slot-scope="{ names }">
                             <b-badge variant="light">{{ names[0] }}</b-badge>
                             <b-badge v-if="names.length > 1" variant="light" class="ml-1">
                               + {{ names.length - 1 }}
                             </b-badge>
                       </template>
                    </b-form-file>
                </div>
            </div>
            <div class="row" v-if="archivos.length>0">
                <div class="col-md-12">
                    <p v-if="archivos.length>0">
                        <strong>Archivos disponibles</strong>
                    </p>
                    <ul class="files-list">
                        <li v-for="archivo in archivos" v-bind:key="archivo.id">
                            <a  class="file-name" :href="baseUrl + archivo.documento__url" target="_blank" download>
                                <font-awesome-icon :icon="['fas', 'file']" />
                                <span class="file-name__text">
                                    {{ archivo.nombre }}
                                    <br>
                                    <i v-if="archivo.existe">{{archivo.user_create__get_nombre_completo}} — <strong>{{archivo.fechahora_created | dateTimeFormat}}</strong></i>
                                    <i v-if="!archivo.existe"><strong>Inhabilitado por:</strong> {{archivo.user_delete__get_nombre_completo}} — <strong>{{archivo.fechahora_deleted | dateTimeFormat}}</strong></i>
                                </span>
                            </a>
                            <a @click="alertaArchivoInhabilitar(archivo);" class="ban-file" v-if="(isAdmin || estadoFicha!=='Validada') && archivo.existe">
                                <font-awesome-icon :icon="['fas', 'ban']" />
                            </a>
                            <a @click="alertaArchivo(archivo);" class="delete-file" v-if="isAdmin">
                                <font-awesome-icon :icon="['fas', 'trash-alt']" />
                            </a>
                        </li>
                    </ul>
                </div>
<!--                 <div class="col-xs-4">
                    <a  v-if="archivos.length > 0" class="btn btn-file pull-right" @click="descargarTodos()">
                        <font-awesome-icon :icon="['fas', 'file-archive']" /> Descargar Archivos
                    </a>
                </div> -->
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModal()">
                    Cancelar
                </b-button>
                <b-button @click="uploadFile()" class="btn btn-primary" :disabled="files.length===0">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></b-button>
            </template>
        </b-modal>
        <div class="mb-5">
            <b-button @click="openModal()" class="btn btn-primary">
                <font-awesome-icon :icon="['fas', 'file']" />  Archivos
            </b-button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'

const ArchivoGET = resource('/api/api/get_archivos/', axios)

export default {
    name: 'ModalArchivos',
    data () {
        return {
            archivos: [],
            files: [],
            fichaId: this.$attrs.ficha,
            instrumentoId: this.$attrs.instrumento,
            isAdmin: this.$attrs.isAdmin,
            estadoFicha: this.$attrs.estadoFicha,
            baseUrl: process.env.VUE_APP_BASEURL,
            acceptedFiles: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/x-rar-compressed,application/zip,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg',
            show: false
        }
    },
    methods: {
        getData () {
            ArchivoGET.query({
                ficha: this.fichaId,
                instrumento: this.instrumentoId
            }).then((res) => {
                this.archivos = res.data
            }, (error) => {
                console.log(error)
            })
        },
        openModal () {
            this.getData()
            this.$root.$emit('bv::show::modal', 'modal-cargar-archivos')
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-cargar-archivos')
        },
        uploadFile () {
            var formData = new FormData()
            for (var i = 0; i < this.files.length; i++) {
                // var file = this.files[i]
                formData.append('documento[' + i + ']', this.files[i])
                // formData.append(`documento${i}`, this.files[i])
            }
            // formData.append('documento', this.files)
            formData.append('ficha', this.fichaId)
            formData.append('instrumento', this.instrumentoId)
            this.show = true
            axios.post('/api/api/cargar_archivos/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$root.$emit('bv::hide::modal', 'modal-cargar-archivos')
                    this.files = []
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Archivos cargados exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al cargar los Archivos'
                    })
                })
        },
        deleteFile (archivo) {
            this.show = true
            axios({
                method: 'delete',
                url: '/api/api/get_archivos/' + archivo.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Archivo eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el Archivo'
                    })
                })
        },
        alertaArchivo (archivo) {
            this.$swal({
                title: 'Vas a eliminar este archivo, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFile(archivo)
                }
            })
        },
        alertaArchivoInhabilitar (archivo) {
            this.$swal({
                title: 'Vas a inhabilitar este archivo, ¿estás seguro?',
                text: 'Se perderá para siempre.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.inhabilitarArchivo(archivo)
                }
            })
        },
        descargarTodos () {
            var formData = new FormData()
            formData.append('ficha', this.fichaId)
            formData.append('instrumento', this.instrumentoId)
            this.show = true
            axios.post('/api/api/get_archivos/descargar/', formData,
                {
                    responseType: 'blob'
                }).then((res) => {
                var fileURL = window.URL.createObjectURL(new Blob([res.data]))
                var fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', 'Archivos.zip')
                document.body.appendChild(fileLink)

                fileLink.click()
                this.show = false
            })
                .catch((res) => {
                    console.log(res)
                    this.show = false
                })
        },
        inhabilitarArchivo (archivo) {
            axios.put('/api/api/get_archivos/' + archivo.id + '/inhabilitar_archivo/').then((res) => {
                if (res.status === 200) {
                    this.getData()
                    this.closeModal()
                }
            })
                .catch((res) => {
                    console.log(res)
                })
        }
    },
    mounted () {
    }
}
</script>
