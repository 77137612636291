<template>
    <div>
        <!--<b-overlay :show="show" no-wrap>
        </b-overlay>-->
        <b-modal :id="'modal-restore-password-' + modal " size="md" title="Cambiar Contraseña">
            <template #modal-title>
                <pre class="modalp-text"><font-awesome-icon :icon="['fas', 'lock']" />   Cambiar Contraseña</pre>
            </template>
            <div class="row">
                <div class="col-md-12">
                <span class="badge"><small class="badge bg-alert" v-if="errorOldPassword">La contraseña anterior no coincide</small></span>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="form-group col-md-11">
                    <div class="modalp-subtext">
                     Para establecer una nueva contraseña, se recomienda lo siguiente:
                    </div>
                    <div class="modalp-lista">
                        <ul>
                            <li>&#9679; &nbsp; &nbsp; Que la nueva contraseña sea distinta a la anterior.</li>
                            <li>&#9679; &nbsp; &nbsp; Que la nueva contraseña tenga al menos ocho caracteres.</li>
                            <li>&#9679; &nbsp; &nbsp; Se aconseja combinar letras y números.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                 <div class="form-group col-md-10">
                    <label>Contraseña <strong>Anterior</strong></label>
                    <input class="form-control" type="password" v-model="formUsuario.old_password">
                </div>
                <div class="col-md-10">
                    <span class="badge"><small class="badge bg-alert" v-if="errorMessage">{{errorMessage}}</small></span>
                    <span class="badge"><small class="badge bg-alert" v-if="errorPassword">Las contraseñas no coinciden</small></span>
                </div>
                <div class="form-group col-md-10">
                    <label>Contraseña <strong>Nueva</strong> (Mínimo 8 carácteres)</label>
                    <input class="form-control" type="password" minlength="8" maxlength="50" v-model="formUsuario.new_password">
                </div>
                <div class="form-group col-md-10">
                    <label>Repita la contraseña <strong>nueva</strong></label>
                    <input class="form-control" type="password" minlength="8" maxlength="50" v-model="formUsuario.new_password2" v-on:blur="validate()">
                </div>
            </div>
            <template #modal-footer>
                <b-button size="md" variant="outline-secondary" @click="closeModal();">
                    Cancelar
                </b-button>
                <a @click="cambiarPassword()" class="btn btn-primary" v-if="(formUsuario.old_password && formUsuario.new_password && formUsuario.new_password2) && !errorPassword">Guardar <font-awesome-icon :icon="['fas', 'check']" /></a>
            </template>
        </b-modal>
        <a @click="openModal()" style="cursor: pointer;" class="card--button__btn">
            <font-awesome-icon :icon="['fas', 'key']" />
            <span class="card--button__text">Cambiar Contraseña</span>
        </a>
    </div>
</template>
<script>
import axios from 'axios'
import router from '../router/index.js'

export default {
    name: 'ModalUsuario',
    data () {
        return {
            modal: this.$attrs.modal,
            formUsuario: {},
            usuario: this.$attrs.usuario,
            errorPassword: false,
            errorOldPassword: false,
            errorMessage: null,
            show: false
        }
    },
    methods: {
        openModal () {
            console.log('a')
            this.formUsuario = {}
            this.$root.$emit('bv::show::modal', 'modal-restore-password-' + this.modal)
        },
        closeModal () {
            this.formUsuario = {}
            this.$root.$emit('bv::hide::modal', 'modal-restore-password-' + this.modal)
        },
        validate () {
            if (this.formUsuario.new_password !== this.formUsuario.new_password2) this.errorPassword = true
            else this.errorPassword = false
        },
        cambiarPassword () {
            if (!this.errorPassword) {
                this.formUsuario.usuario = this.usuario
                this.show = true
                axios({
                    method: 'put',
                    url: '/usuarios/api/cambiar_password/' + this.usuario.id + '/set_password/',
                    data: this.formUsuario
                })
                    .then((response) => {
                        this.show = false
                        if (response.status === 200) {
                            this.closeModal()
                            this.formUsuario = {}
                            this.$notify({
                                group: 'foo',
                                type: 'success',
                                title: 'Contraseña cambiada exitosamente'
                            })
                            this.$swal({
                                title: 'Se cambio su contraseña correctamente',
                                text: 'Ingresa nuevamente al sistema.',
                                icon: 'success',
                                showCancelButton: false,
                                showConfirmButton: true
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    router.push('/login?logout')
                                    location.reload()
                                }
                            })
                        } else if (response.status === 204) {
                            this.errorOldPassword = true
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Error al cambiar la Contraseña'
                            })
                        }
                    })
                    .catch((error) => {
                        this.show = false
                        console.log(error.response)
                        if (error.response.status === 400 && error.response.data.new_password.length > 0) {
                            this.errorMessage = error.response.data.new_password[0]
                        }
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Error al cambiar la Contraseña'
                        })
                    })
            }
        }
    },
    mounted () {
    }
}
</script>
