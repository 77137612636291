// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import 'bootstrap'
import { BootstrapVue } from 'bootstrap-vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueFormulate from '@braid/vue-formulate'
import moment from 'moment'
import Notifications from 'vue-notification'
import { rutFilter, rutInputDirective } from 'vue-dni'
import VueSweetalert2 from 'vue-sweetalert2'
import VueDraggable from 'vue-draggable'
import vSelect from 'vue-select'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-select/dist/vue-select.css'
import './app.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faHome, faFile, faHeading,
    faAlignLeft, faCheck, faTimes, faThList,
    faIndent, faSpinner, faImage, faTable,
    faSignInAlt, faEquals, faDotCircle, faCheckSquare,
    faCaretSquareDown, faUpload, faAlignJustify, faEye, faPlus,
    faEdit, faEllipsisV, faArrowRight, faTrashAlt,
    faCheckDouble, faUserTie, faArrowLeft, faBan, faCircle,
    faUsers, faCloudDownloadAlt, faCloudUploadAlt, faToggleOn,
    faSyncAlt, faSquareRootAlt, faFont, faChevronRight, faChevronDown, faEraser,
    faCog, faComment, faFileArchive, faInfoCircle, faProjectDiagram,
    faChartBar, faSave, faMinusCircle, faPlusCircle, faFileAlt,
    faPencilAlt, faCopy, faUserCog, faBars, faDownload, faQuestionCircle, faArrowsAltV,
    faSort, faUnlockAlt, faUnlock, faPencilRuler, faSearch, faChartLine,
    faSignOutAlt, faFileMedicalAlt, faKey, faLock

} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

window.$ = window.jQuery = require('jquery')

Vue.use(BootstrapVue)
Vue.use(CKEditor)
Vue.use(VueFormulate)
Vue.use(Notifications)
Vue.use(VueSweetalert2)
Vue.use(VueDraggable)

Vue.component('v-select', vSelect)

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faHome, faLinkedin, faFile, faHeading,
    faAlignLeft, faCheck, faTimes, faThList,
    faIndent, faSpinner, faImage, faTable,
    faSignInAlt, faEquals, faDotCircle, faCheckSquare,
    faCaretSquareDown, faUpload, faAlignJustify, faEye, faPlus,
    faEdit, faEllipsisV, faArrowRight, faTrashAlt,
    faCheckDouble, faUserTie, faArrowLeft, faBan, faCircle,
    faUsers, faCloudDownloadAlt, faCloudUploadAlt, faToggleOn,
    faSyncAlt, faSquareRootAlt, faFont, faChevronRight, faChevronDown, faEraser,
    faCog, faComment, faFileArchive, faInfoCircle, faProjectDiagram,
    faChartBar, faSave, faSquare, faMinusCircle, faPlusCircle, faFileAlt,
    faPencilAlt, faCopy, faUserCog, faBars, faDownload, faQuestionCircle,
    faArrowsAltV, faSort, faUnlockAlt, faUnlock, faPencilRuler, faSearch, faChartLine,
    faSignOutAlt, faFileMedicalAlt, faKey, faLock
)
Vue.use(VueFormulate)

Vue.config.productionTip = false

Vue.filter('dateFormat', function (value) {
    if (!value) return ''
    value = value.toString()
    return moment(value).format('DD/MM/YYYY')
})

Vue.filter('dateTimeFormat', function (value) {
    if (!value) return ''
    value = value.toString()
    return moment(value).format('DD/MM/YYYY HH:mm')
})

Vue.filter('uppercase', function (value) {
    if (!value) return ''
    return value.toUpperCase()
})
Vue.filter('validationFormat', function (value) {
    if (!value) return 'No'
    return 'Sí'
})

Vue.filter('round', function (value) {
    if (!value) return ''
    return value.toFixed(2)
})
Vue.filter('rutFilter', rutFilter)
Vue.directive('rut', rutInputDirective)

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
