<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Inicio</a></li>
                        <li class="breadcrumb-item" v-if="nombreAnterior"><router-link :to="urlAnterior">{{nombreAnterior}}</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">{{nombreActual}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Breadcrumb',
    data () {
        return {
            urlAnterior: this.$attrs.urlAnterior,
            nombreAnterior: this.$attrs.nombreAnterior,
            nombreActual: this.$attrs.nombreActual
        }
    }
}
</script>
