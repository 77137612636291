<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="logoLink">
                <img class="navbar-brand__img" src="/img/logo.svg">
            </router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <font-awesome-icon :icon="['fas', 'bars']"/>
            </button>
            <div class="header-name" v-if="pathName!=='Login' && logged">
                Usuario/a:
                <span class="header-name2">{{usuario.get_nombre_completo}}</span>
            </div>
            <div class="collapse navbar-collapse" id="navbarNav" v-if="pathName!=='Login' && logged">
                <ul class="navbar-nav ml-auto align-items-baseline">
                    <li class="nav-item">
                        <router-link :class="[currentPage === '/' ? activeClass: '', 'nav-link']" to="/" id="header-item">
                            <div class="nav-item-text"><font-awesome-icon :icon="['fas', 'home']" size="3x" /></div>
                            Inicio
                        </router-link>
                    </li>
                    <li class="nav-item dropdown" id="header-item" v-if="usuario.tipo_usuario === 'Administrador'">
                        <router-link :class="[currentPage === '/panel_administracion' ? activeClass: '', 'nav-link dropdown-toggle']" to="/panel_administracion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="nav-item-text"><font-awesome-icon :icon="['fas', 'cog']" size="3x" /></div>
                            Administración
                        </router-link>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item" href="/listar_fichas">Fichas</a>
                            <a class="dropdown-item" href="/listar_monitoreos">Monitoreos</a>
                            <a class="dropdown-item" href="/listar_agencias">Agencias</a>
                            <a class="dropdown-item" href="/listar_usuarios">Usuarios</a>
                            <a class="dropdown-item" href="/crear_tipo_indicador">Tipo de Indicador</a>
                            <a class="dropdown-item" href="/crear_unidad_medida">Unidad de Medida</a>
                        </div>
                    </li>
                    <li class="nav-item" id="header-item">
                        <router-link :class="[currentPage === '/fichas_diseno' ? activeClass: '', 'nav-link']" to="/fichas_diseno">
                            <div class="nav-item-text"><font-awesome-icon :icon="['fas', 'pencil-ruler']" size="3x" /></div>
                            Diseño
                        </router-link>
                    </li>
                    <li class="nav-item" id="header-item">
                        <router-link :class="[currentPage === '/monitoreo_instrumentos' ? activeClass: '', 'nav-link']" to="/monitoreo_instrumentos">
                            <div class="nav-item-text"><font-awesome-icon :icon="['fas', 'search']" size="3x" /></div>
                            Monitoreo
                        </router-link>
                    </li>
                    <li class="nav-item" id="header-item">
                        <router-link :class="[currentPage === '/fichas_evaluacion' ? activeClass: '', 'nav-link']" to="/fichas_evaluacion">
                            <div class="nav-item-text"><font-awesome-icon :icon="['fas', 'chart-line']" size="3x" /></div>
                            Evaluación
                        </router-link>
                    </li>
                    <li class="nav-item" id="header-item">
                        <a @click="logout()" class="nav-link" style="cursor: pointer; color: #fff">
                            <div class="nav-item-text"><font-awesome-icon :icon="['fas', 'sign-out-alt']" size="3x" /></div>
                            Salir
                        </a>
                    <!--<li class="nav-item dropdown" >
                        <a data-toggle="dropdown" class="nav-link dropdown-toggle" aria-haspopup="true" aria-expanded="false"><font-awesome-icon :icon="['fas', 'user-cog']" style="font-size: 16px"/></a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">-->
                            <!-- <a class="dropdown-item" @click="cambiarPassword()" style="cursor: pointer;">Cambiar Contraseña</a> -->
                            <!--<modal-password :usuario="usuario"></modal-password>
                            <a class="dropdown-item" @click="logout()" style="cursor: pointer;">Cerrar Sesión</a>
                        </div>-->
                    </li>
<!--                     <li class="nav-item">
                        <a :class="[currentPage === '/login?logout' ? activeClass: '', 'nav-link']" @click="logout()">Cerrar Sesión</a>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import router from '../router/index.js'
/* import ModalPassword from '@/components/ModalPassword.vue' */

const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)
export default {
    name: 'Header',
    components: {
        // ModalPassword
    },
    data () {
        return {
            usuario: {},
            activeClass: 'nav-link--active',
            pathName: this.$route.name,
            logoLink: '/',
            logged: false
        }
    },
    computed: {
        currentPage () {
            return this.$route.path
        }
    },
    methods: {
        logout () {
            this.logged = false
            router.push('/login?logout')
            location.reload()
        }
    },
    mounted () {
        // console.log(this.pathName)
        if (this.pathName === 'Login') {
            this.logoLink = ''
        }
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            this.logged = true
        }).catch((error) => {
            console.log(error)
            this.logged = false
        })
    }
}
</script>
