<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <b-modal :id="modal" size="lg" :title="'Indicador de ' + tipoIndicadorNombre">
            <div class="row">
                <div class="form-group col-md-6">
                    <label>Nombre*</label>
                    <input type="text" name="" class="form-control" v-model="formIndicador.nombre">
                </div>
                <div class="form-group col-md-6">
                    <label>Detalle Metodología*</label>
                    <textarea class="form-control" v-model="formIndicador.formula_calculo"></textarea>
                </div>
                <div class="form-group col-md-6">
                    <label>Periodicidad*</label>
                    <v-select :options="periodos" v-model="periodicidad" label="value" :reduce="value => value.key"></v-select>
                </div>
                <div class="form-group col-md-6">
                    <label>Fuente de Información*</label>
                    <textarea class="form-control" v-model="formIndicador.fuente_informacion"></textarea>
                </div>
                <div class="form-group col-md-6">
                    <label>Situación Actual</label>
                    <textarea class="form-control" v-model="formIndicador.situacion_actual"></textarea>
                </div>
                <div class="form-group col-md-6">
                    <label>Situación Esperada</label>
                    <textarea class="form-control" v-model="formIndicador.situacion_esperada"></textarea>
                </div>
                <!--<div class="form-group col-md-6">
                    <label>Justificación de Meta</label>
                    <textarea class="form-control" v-model="formIndicador.justificacion_meta"></textarea>
                </div>-->
                <div class="form-group col-md-6">
                    <label>Unidad de Medida*</label>
                    <v-select :options="unidadMedidas" v-model="formIndicador.unidad_medida" v-on:input="setVariables();change=true;" label="nombre" :reduce="nombre => nombre.id">
                       <template slot="option" slot-scope="option">
                           {{ option.nombre }}<strong>({{option.unidad}})</strong>
                       </template>
                       <template slot="selected-option" slot-scope="option">
                           {{ option.nombre }}<strong>({{option.unidad}})</strong>
                       </template>
                    </v-select>
                </div>
                <div class="form-group col-md-6 text-center">
                    <label>¿Es Dipres?</label>
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="formIndicador.dipres"
                        name="checkbox-1">
                    </b-form-checkbox>
                </div>
                <div class="form-group col-md-12" v-if="formIndicador.variables">
                    <label>Fórmula: </label>
                    <strong> {{formulaMedida}}</strong>
                    <span v-if="!cantidadVariables">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Agregar Variables* </label>
                                <b-button @click="addVariable(formVariables)" class="btn btn-sm"><font-awesome-icon :icon="['fas', 'plus']" /></b-button>
                                <textarea class="form-control" v-model="formVariables.valor" placeholder="ej. Suma de publicaciones en Q1 en WoS  asociadas al instrumento en año t"></textarea>
                            </div>
                            <!-- <b-button @click="addVariable(formVariables)" class="btn btn-sm"><font-awesome-icon :icon="['fas', 'plus']" /></b-button> -->
                        </div>
                    </span>
                    <span v-if="cantidadVariables || !cantidadVariables && formIndicador.variables.length > 0">
                        <div class="row" v-for="(variable, index) in formIndicador.variables" v-bind:key="index">
                            <div class="col-md-10">
                                <label>Definición de Variable <strong>{{variable.variable}}</strong>*</label>
                                <textarea type="text" name="" class="form-control" v-model="variable.valor" placeholder="ej. Suma de publicaciones en Q1 en WoS  asociadas al instrumento en año t"></textarea>
                            </div>
                            <div class="col-md-2" v-if="!cantidadVariables && formIndicador.variables.length > 0">
                                <a @click="removeVariable(index)" role="button" class="btn-icon-remove">Eliminar</a>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <b-button size="md" variant="outline-secondary" @click="cancel();">
                    Cancelar
                </b-button>
                <a v-if="!editar" @click="createIndicador()" class="btn btn-primary">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
                <a v-if="editar" @click="updateIndicador(formIndicador);" class="btn btn-primary">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
            </template>
        </b-modal>
        <div :class="{ '': !editar && !monitoreoId }">
            <b-button v-b-modal.modal-create-indicador variant="primary" :class="{ 'btn btn-primary btn-sm': !editar && !monitoreoId, 'btn btn--white btn-sm': editar || monitoreoId }" @click="openModal()">
                <span v-if="editar">
                    <font-awesome-icon :icon="['fas', 'edit']"/> Editar
                </span>
                <span v-if="!editar && !monitoreoId">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                        Crear Indicador de {{tipoIndicadorNombre}}
                </span>
                <span v-if="!editar && monitoreoId">
                    <font-awesome-icon :icon="['fas', 'plus']"/> Crear Indicador de {{tipoIndicadorNombre}}
                </span>
            </b-button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'

const UnidadMedida = resource('/indicadores/api/listar_unidad_medida', axios)

export default {
    name: 'ModalIndicador',
    data () {
        return {
            modal: this.$attrs.modal,
            formIndicador: this.$attrs.formIndicador,
            fichaId: this.$attrs.ficha,
            monitoreoId: this.$attrs.monitoreo,
            instrumentoId: this.$attrs.instrumento,
            tipoIndicadorId: this.$attrs.tipoIndicador,
            tipoIndicadorNombre: this.$attrs.tipoIndicadorNombre,
            monitoreoInstrumentoId: this.$attrs.monitoreoInstrumento,
            editar: this.$attrs.editar,
            anio: this.$attrs.anio_,
            unidadMedidas: [],
            periodos: [
                { key: 12, value: 'Anual' },
                { key: 1, value: 'Mensual' },
                { key: 2, value: 'Bimestral' },
                { key: 3, value: 'Trimestral' },
                { key: 4, value: 'Cuatrimestral' },
                { key: 6, value: 'Semestral' }
            ],
            periodicidad: 12,
            cantidadVariables: null,
            formVariables: {},
            formulaMedida: null,
            change: false,
            show: false
        }
    },
    methods: {
        openModal () {
            if (this.editar) this.setVariables()
            if (!this.editar) this.formIndicador = {}
            this.$root.$emit('bv::show::modal', this.modal)
        },
        closeModal () {
            this.$emit('accept')
            this.$root.$emit('bv::hide::modal', this.modal)
        },
        setVariables () {
            this.unidadMedidas.filter(unidad => {
                if (unidad.id === this.formIndicador.unidad_medida) {
                    this.formulaMedida = unidad.formula
                    this.cantidadVariables = unidad.cantidad_variables
                }
            })
            if (!this.editar || this.change) {
                var variablesList = []
                if (this.cantidadVariables) {
                    for (var i = 1; i < this.cantidadVariables + 1; i++) {
                        var variablesDict = {}
                        var s = ''
                        var t = ''
                        t = (i - 1) % 26
                        s = String.fromCharCode(65 + t) + s
                        variablesDict.variable = s
                        variablesDict.valor = ''
                        variablesList.push(variablesDict)
                    }
                    this.formIndicador.variables = variablesList
                } else {
                    this.formIndicador.variables = []
                }
            }
        },
        addVariable (variables) {
            var num = this.formIndicador.variables.length + 1
            var t = ''
            var s = ''
            t = (num - 1) % 26
            s = String.fromCharCode(65 + t) + s
            variables.variable = s
            this.formIndicador.variables.push(variables)
            this.formVariables = {}
        },
        removeVariable (index) {
            this.formIndicador.variables.splice(index, 1)
            this.formVariables = {}
        },
        createIndicador () {
            var mi = []
            if (this.monitoreoInstrumentoId) mi.push(this.monitoreoInstrumentoId)
            this.formIndicador.anio = this.anio
            this.formIndicador.ficha = this.fichaId
            this.formIndicador.monitoreo = this.monitoreoId
            this.formIndicador.instrumento = this.instrumentoId
            this.formIndicador.tipo_indicador = this.tipoIndicadorId
            this.formIndicador.periodicidad = this.periodicidad
            this.formIndicador.monitoreo_instrumento = mi
            this.show = true
            axios({
                method: 'post',
                url: '/indicadores/api/crear_indicador_ficha/',
                data: this.formIndicador
            })
                .then((response) => {
                    this.show = false
                    this.closeModal()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Indicador creado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    console.log(response)
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el Indicador'
                    })
                })
        },
        updateIndicador (indicador) {
            this.formIndicador.anio = this.anio
            this.formIndicador.ficha = this.fichaId
            this.formIndicador.monitoreo = this.monitoreoId
            this.formIndicador.instrumento = this.instrumentoId
            this.formIndicador.tipo_indicador = this.tipoIndicadorId
            this.show = true
            axios({
                method: 'put',
                url: '/indicadores/api/crear_indicador_ficha/' + indicador.id + '/',
                data: this.formIndicador
            })
                .then((response) => {
                    this.show = false
                    this.closeModal()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Indicador actualizado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    console.log(response)
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al actualizar el Indicador'
                    })
                })
        }
    },
    mounted () {
        UnidadMedida.query({}).then((res) => {
            this.unidadMedidas = res.data
        }, (error) => {
            console.log(error)
        })
    }
}
</script>
